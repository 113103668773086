<template>
  <div class="container">
    <div class="container-kids">
      <div class="wrapper-title-amount container-kids__wrapper-title-amount">
        <BaseH1 :text="'Ile masz dzieci?'" class="h1" />

        <BaseInputNumberSpinner
          v-model="offer.kids.amount"
          :name="'kids-amount'"
          :max="5"
          :min="1"
        />
      </div>
    </div>

    <KidsInputList
      v-if="offer.kids.value.length"
      :kids="offer.kids.value"
      @update:kid="updateKid($event)"
    />

    <div class="container-special-care">
      <div class="wrapper-title container-special-care__wrapper-title">
        <BaseH1 :text="'Czy potrzebna specjalna troska?'" class="h1" />
        <TextDescription :text="'Dla osób niepełnosprawnych'" />
      </div>

      <InputCheckboxButton
        v-model="offer.isSpecialCare"
        :name="'special-care'"
        :label="'Potrzebna'"
        :value="''"
        :checked="offer.isSpecialCare"
      />
    </div>
  </div>
</template>

<script>
import { reactive } from "@vue/reactivity";
import { onMounted, watch } from "@vue/runtime-core";
import { generateUID } from "@/helpers";

import BaseH1 from "@/components/UI/Base/BaseH1.vue";
import BaseInputNumberSpinner from "@/components/UI/Base/BaseInputNumberSpinner.vue";
import TextDescription from "@/components/UI/TextDescription.vue";
import KidsInputList from "@/components/KidsInputList.vue";
import InputCheckboxButton from "@/components/UI/Base/InputCheckboxButton.vue";

export default {
  components: {
    BaseH1,
    BaseInputNumberSpinner,
    TextDescription,
    KidsInputList,
    InputCheckboxButton,
  },

  props: {
    kids: Array,
    isSpecialCare: Boolean,
  },

  emits: ["update:kids", "update:isSpecialCare"],

  setup(props, { emit }) {
    const offer = reactive({
      kids: {
        value: props.kids,
        amount: props.kids.length,
      },
      isSpecialCare: props.isSpecialCare,
    });

    // Way out binding
    watch(
      () => offer.kids.value,
      (newKids) => emit("update:kids", newKids),
      { deep: true }
    );
    watch(
      () => offer.isSpecialCare,
      (newIsSpecialCare) => emit("update:isSpecialCare", newIsSpecialCare)
    );

    // Way in binding
    watch(
      () => props.kids,
      (newKids) => {
        console.log("watcher: update kids from outside");
        offer.kids.value = newKids;
        offer.kids.amount = newKids.length;
      },
      { deep: true }
    );
    watch(
      () => props.isSpecialCare,
      (newIsSpecialCare) => (offer.isSpecialCare = newIsSpecialCare)
    );

    const initKids = () => {
      // If NO kids => create new default kid
      if (offer.kids.value.length === 0) {
        offer.kids.amount = 1;
      }
    };

    // On kidsAmount changed add/delete kids
    watch(
      () => offer.kids.amount,
      (newKidsAmount, oldKidsAmount) => {
        // Stop func if kids.amount === actual kids amount in kids array
        if (newKidsAmount === offer.kids.value.length) return;

        // Set proper types
        if (typeof newKidsAmount !== "number") {
          newKidsAmount = Number(newKidsAmount);
        }
        if (typeof oldKidsAmount !== "number") {
          oldKidsAmount = Number(oldKidsAmount);
        }

        // Calculate change difference
        let diff = Math.abs(newKidsAmount - oldKidsAmount);

        if (newKidsAmount > oldKidsAmount) {
          // Create new kids
          while (diff > 0) {
            diff--;
            createKid();
          }
        } else if (newKidsAmount < oldKidsAmount) {
          // Delete odd kids
          while (diff > 0) {
            diff--;
            deleteLastKid();
          }
        }
      }
    );

    // Create new kid
    const createKid = ({ id, sex = "girl", age = 3 } = {}) => {
      if (!id) {
        id = generateUID();
      }
      if (typeof sex !== "string") {
        age = toString(sex);
      }
      if (typeof age !== "number") {
        age = Number(age);
      }
      offer.kids.value.push({ id: id, sex: sex, age: age });
    };

    // Update kid
    const updateKid = (newKid) => {
      let index = offer.kids.value.findIndex((kid) => {
        return kid.id === newKid.id;
      });
      offer.kids.value[index] = newKid;
    };

    // Delete last kid
    const deleteLastKid = () => {
      offer.kids.value.pop();
    };

    onMounted(() => {
      initKids();
    });

    return {
      offer,
      updateKid,
    };
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  gap: 80px;
}

.container-kids__wrapper-title-amount {
  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 60px;
}
.container-special-care {
  margin-top: 20px;
  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 60px;
}
.container-special-care__wrapper-title {
  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 20px;
}

@media (max-width: 1200px) {
  .container {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    gap: 60px;
  }
}
</style>
