<template>
  <InputRadioGroupHorizontal
    :name="name"
    :widthSmall="true"
    :heightSmall="true"
    :options="sexOptions"
    @update:modelValue="$emit('update:modelValue', $event)"
    :modelValue="modelValue"
    class="sex-container"
  />
</template>

<script>
export default {
  props: {
    modelValue: {
      type: String,
    },
    name: {
      type: String,
      reuquired: true,
    },
  },

  setup() {
    const sexOptions = [
      {
        label: "Dziewczynka",
        value: "girl",
      },
      {
        label: "Chłopczyk",
        value: "boy",
      },
    ];
    return { sexOptions };
  },
};
</script>

<style scoped>
.sex-container {
  --button-width: 150px;
  --radio-button-horizontal--column-gap: 20px;
}
@media (max-width: 1200px) {
  .sex-container {
    --button-width: 146px;
    --radio-button-horizontal--column-gap: 10px;
  }
}
</style>
