<template>
  <div class="wrapper-kids">
    <KidsInputListItem
      v-for="kid in kids"
      :key="kid.id"
      :kid="kid"
      @update:kid="$emit('update:kid', $event)"
    />
  </div>
</template>

<script>
import KidsInputListItem from "@/components/KidsInputListItem.vue";

export default {
  components: {
    KidsInputListItem,
  },

  props: {
    kids: {
      type: Array,
      required: true,
    },
  },

  emits: ["update:kid"],

  setup() {
    return {};
  },
};
</script>

<style scoped>
.wrapper-kids {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: 40px;
}

@media (max-width: 1200px) {
}
</style>
