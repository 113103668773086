<template>
  <div class="wrapper-kid">
    <KidsInputListItemSex v-model="kidSex" :name="'sex-' + kidId" />
    <KidsInputListItemAge v-model="kidAge" :name="'age-' + kidId" />
  </div>
</template>

<script>
import { ref, watch, computed } from "vue";

import KidsInputListItemSex from "@/components/KidsInputListItemSex.vue";
import KidsInputListItemAge from "@/components/KidsInputListItemAge.vue";

export default {
  components: {
    KidsInputListItemSex,
    KidsInputListItemAge,
  },

  props: {
    kid: {
      type: Object,
      required: true,
    },
  },

  emits: ["update:kid"],

  setup(props, { emit }) {
    const kidSex = ref(props.kid.sex);
    const kidAge = ref(props.kid.age);

    // Get kid Id or Set if Undefined
    const kidId = computed(() => {
      return props.kid.id || ref(Date.now());
    });

    // Update kid when kid prop is changed
    watch(
      () => props.kid,
      (newKid) => {
        if (newKid.sex) kidSex.value = newKid.sex;
        if (newKid.age) kidAge.value = newKid.age;
      },
      { deep: true }
    );

    // Emit update:recommendation on inputs
    watch([kidId, kidSex, kidAge], () => {
      emit("update:kid", {
        id: kidId.value,
        sex: kidSex.value,
        age: kidAge.value,
      });
    });

    return {
      kidId,
      kidSex,
      kidAge,
    };
  },
};
</script>

<style scoped>
.wrapper-kid {
  width: 360px;
  padding: 35px 0;

  display: flex;
  flex-flow: column;
  align-items: center;

  row-gap: 25px;

  background: rgba(255, 198, 198, 0.15);
  border-radius: 20px;
}

.wrapper-kid:nth-child(2) {
  background: rgba(255, 247, 240, 1);
}
.wrapper-kid:nth-child(3) {
  background: rgba(255, 255, 248, 1);
}
.wrapper-kid:nth-child(4) {
  background: rgba(249, 249, 255, 1);
}
.wrapper-kid:nth-child(5) {
  background: red;
}

@media (max-width: 1200px) {
  .wrapper-kid {
    width: 100%;
    padding: 30px 0;

    row-gap: 20px;
  }
}
</style>
