<template>
  <div class="content">
    <OfferNannyInputKids
      v-model:kids="offerNanny.details.kids"
      v-model:is-special-care="offerNanny.details.isSpecialCare"
    />

    <ButtonPrimaryNext :to="{ name: 'OfferNannyCreateEmploymentType' }" />
  </div>
</template>

<script>
import { useOfferNannyStore } from "@/stores/offerNanny";

import OfferNannyInputKids from "@/components/Offer/Nanny/OfferNannyInputKids.vue";
import ButtonPrimaryNext from "@/components/UI/ButtonPrimaryNext.vue";

export default {
  components: {
    OfferNannyInputKids,
    ButtonPrimaryNext,
  },

  setup() {
    const offerNanny = useOfferNannyStore();

    return {
      offerNanny,
    };
  },
};
</script>

<style scoped>
.content {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  gap: 80px;
}

@media (max-width: 1200px) {
}
</style>
