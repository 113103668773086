<template>
  <div class="container-age">
    <base-text class="label container-age__label">Wzrost (lata)</base-text>
    <BaseInputNumberSpinner
      :name="name"
      :max="30"
      :min="0"
      :small="true"
      @update:model-value="$emit('update:modelValue', $event)"
      :model-value="shownAge"
      :isMasked="false"
    />
  </div>
</template>

<script>
import { computed } from "vue";

import BaseInputNumberSpinner from "./UI/Base/BaseInputNumberSpinner.vue";

export default {
  components: { BaseInputNumberSpinner },
  props: {
    modelValue: {
      type: [String, Number],
      default: 1,
    },
    name: {
      type: String,
      reuquired: true,
    },
  },

  emits: ["update:modelValue"],

  setup(props) {
    const shownAge = computed(() => {
      if (props.modelValue === 0) return "<1";
      return props.modelValue;
    });
    return { shownAge };
  },
};
</script>

<style scoped>
.container-age {
  display: flex;
  flex-flow: column;
  row-gap: 20px;
}
.container-age__label {
  font-weight: 500;
  text-align: center;
}
</style>
